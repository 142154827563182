import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import emailjs from 'emailjs-com';
import {useNavigate} from "react-router-dom";
import Api from '../Api/Api';
import PropTypes from "prop-types";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import {qr} from '../constants';
import {useUserController, setActiveQuiz, resetActiveQuiz} from "../context/user";
import {useMaterialUIController} from "../context";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  maxWidth: '98%',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  li: {
    listStyleType: 'decimal'
  },
  h2: {
    textAlign: 'center'
  }
};

export default function ResultsModal(props) {

  let cb = props.cb;


  const [controller] = useMaterialUIController();
  const {spanish} = controller;
  const [userController, userDispatch] = useUserController();
  const {user, activeQuiz} = userController;
  //for dev testing
  const handsOn = props.data.cat === 'f9eab94e-9e31-4d67-90cc-5ed40d2453f3' ? true : false
  const template = props.data.id === "9e4633aa-9ff9-4fcf-a9eb-88677f9035eb" ? 'dev_template' : 'prod_template'


  let navigate = useNavigate();

  user.currentVideo = ''

  const [opened, setOpened] = React.useState(false);
  const [cat, setCat] = React.useState('')
  const [generating, setGenerating] = React.useState(false)
  const [allELists, setAllELists] = React.useState([])
  const [eList, setEList] = React.useState([])

  React.useEffect(() => {
    Api.getOneQuiz(props.data.id).then(res => {
      setEList(res.data[0].listsUsed)
      return res.data[0].cat
    }).then(c => {
      Api.getOneCat(c).then(res => {
        setCat(res.data[0].title)
      })
    })

    // Api.getAllELists().then(data => {
    //   setAllELists(data.data)
    //   return true
    // }).then(res => {
    // })
  }, [])

  // toggle on if quiz fail, no cert
  // return to beginning of video
  const handleOpen = async () => {
    const score = await cb();
    // console.log(score, "SCORE")
    setOpened(true);
    if (score >= 70) {
      setGenerating(true);
      await didPass(score);
    }
  };

  const didPass = async (score) => {
    // user.updateVData(null)
    // console.log(activeQuiz, props, "PROPS")
    // user.updateCrwUrl(null)
    await sendEmail(score);
  }

  const handleClose = () => {
    setOpened(false);
    resetActiveQuiz(userDispatch)
    goHome();
  };

  const getSpecifiedEmails = async () => {
    const emails = []

    let emailString = ''

    allELists.forEach((el, i) => {
      eList.forEach((l, j) => {
        if (el.id === l) {

          el.emails.forEach(em => {
            !el.isDeleted && !em.isDeleted && emails.push(em.email)
          })
        }
      })
    })

    emailString = emails.join(",")

    return emailString;
  }

  const sendEmail = async (score) => {

    const emails = eList && await getSpecifiedEmails();

    const qrCode = handsOn ? qr : null
    const qrUrl = handsOn ? 'Hands On Training Form: https://forms.office.com/Pages/ResponsePage.aspx?id=GXqmInPrOkqJkwQaKPAWYMsuunUB6UBFv8qDuDebq_RURExQQzlYSUdKWkJaTFhLSTUyREM4MTE1QS4u&embed=true' : ''

    let title = props.data.english.title;

    //hotfix for missing cookie update
    let name = 'contact developer';
    name = user.FirstName + ' ' + user.LastName;
    const userCookie = cookies.get('user')

    let now = new Date();
    let dd = String(now.getDate()).padStart(2, '0');
    let mm = String(now.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = now.getFullYear();

    let h = now.getHours();
    let m = now.getMinutes();
    let s = now.getSeconds();

    let today = mm + '/' + dd + '/' + yyyy;
    const today_str = mm + dd + yyyy + '' + h + '' + m + '' + s


    let params = {
      emp_name: name,
      emp_email: user.email,
      jobClassification: user.JobClassification,
      userID: userCookie?.userId || activeQuiz?.userId || user?.id,
      category: cat,
      metadata: activeQuiz,
      certification_title: title,
      quiz_score: score,
      todays_date: today || "",
      cert_name: title + '-' + today_str,
      cert_url: '',
      specified_emails: emails ? emails + ',' : '',
      // ^ for item 4
      handsonqr: qrCode,
      handsonurl: qrUrl
    }
    console.log(params, "PARAMS")
    const certUrl = await createCert(params)
    params.cert_url = certUrl
    //
    let data = {
      service_id: 'service_leemyzb',
      template_id: template,
      user_id: 'OEpIsDjbJHxouxTll',
      template_params: params
    };

    emailjs.send(data.service_id, data.template_id, data.template_params, data.user_id)
      .then((result) => {
        setGenerating(false)
      }, (error) => {
        console.log(false)
        setGenerating(false)
      });

  }

  const createCert = async (params) => {
    // console.log(params.name, params.emp_name);
    let res = await Api.createCert(params)

    return res.data
  }

  const goHome = () => {
    navigate(-1)
  }

  const resultsResponse = () => {
    let pass = "You have passed this evaluation.";
    let fail = "The minimum score to pass this evaluation is 70. You will need to retake the quiz. Click to return to the main page, re-watch the training video, and try again."

    return props.score >= 70 ? pass : fail
  }

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} disabled={props.dis}>{props.spanish ? "Enviar" : "Submit"}</Button>
      <Modal
        open={opened}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        color="primary"
      >
        <Box sx={{
          ...style,
          width: 600,
          minHeight: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center'
        }}>
          {generating ?
            <>
              <h2 id="parent-modal-title">EVALUATING...</h2>
              <p>{spanish ? 'Cuestionario de evaluación. No abandone esta página hasta que los resultados estén listos o deberá volver a realizar el cuestionario.' : 'Evaluating quiz. Please do not leave this page until results are ready or quiz will need to be retaken.'}</p>
            </>
            :
            <>
              <h2 id="parent-modal-title">RESULTS</h2>
              <p>
                You have completed this quiz with a score of {props.score}.
              </p>
              <p>
                {resultsResponse()}
              </p>
              <p>
                {props.score >= 70 && "A copy of your results has been emailed to you and to the training department to be kept on record in your file."}
              </p>
              <Button style={{marginTop: 20}} variant="contained" onClick={handleClose}>Exit</Button>
            </>
          }
        </Box>
      </Modal>
    </div>
  );
}

ResultsModal.propTypes = {
  data: PropTypes.object,
  user: PropTypes.object,
  cb: PropTypes.any,
  userId: PropTypes.any,
  spanish: PropTypes.bool,
  dis: PropTypes.bool,
  score: PropTypes.any,


}
