import axios from "axios";

/////// switch these when in dev for the backend
 axios.defaults.baseURL = "https://mbt333.azurewebsites.net/";
 // axios.defaults.baseURL = "http://localhost:5000/";
const Api = {
  ///// QUIZZES

  getAllQuizzes() {
    try {
      return axios("api/quizzes/getall", {
        method: "get",
      });
    } catch (err) {
      return err;
    }
  },

  getOneQuiz(qid) {
    try {
      return axios("api/quizzes/getone/" + qid, {
        method: "get",
        id: qid,
      });
    } catch (err) {
      return err;
    }
  },

  delOneQuiz(qid) {
    try {
      return axios("api/quizzes/delone/" + qid, {
        method: "delete",
        id: qid,
      });
    } catch (err) {
      return err;
    }
  },

  newQuiz(data) {
    try {
      return axios("api/quizzes/newquiz", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  updateQuiz(data) {
    try {
      return axios("api/quizzes/update", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  ///// CATEGORIES

  getAllCats() {
    try {
      return axios("api/categories/getall", {
        method: "get",
      });
    } catch (err) {
      return err;
    }
  },

  getOneCat(cid) {
    try {
      return axios("api/categories/getone/" + cid, {
        method: "get",
        id: cid,
      });
    } catch (err) {
      return err;
    }
  },

  newCat(data) {
    try {
      return axios("api/categories/newcat", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  updateCat(data) {
    try {
      return axios("api/categories/update", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  ///// EMAIL LISTS

  getAllELists() {
    try {
      return axios("api/emaillists/getall", {
        method: "get",
      });
    } catch (err) {
      return err;
    }
  },

  getOneEList(elid) {
    try {
      return axios("api/emaillists/getone/" + elid, {
        method: "get",
        id: elid,
      });
    } catch (err) {
      return err;
    }
  },

  newEList(data) {
    try {
      return axios("api/emaillists/newcat", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  updateEList(data) {
    try {
      return axios("api/emaillists/update", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  ///// VIDEOS

  getAllVideos() {
    try {
      return axios("api/videos/getall", {
        method: "get",
      });
    } catch (err) {
      return err;
    }
  },

  getOneVideo(vid) {
    try {
      return axios("api/videos/getone/" + vid, {
        method: "get",
        id: vid,
      });
    } catch (err) {
      return err;
    }
  },

  updateVideo(data) {
    try {
      return axios("api/videos/update", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  /////// EMAIL

  sendEmail(data) {
    try {
      return axios("api/email/", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  createCert(data) {
    try {
      return axios("api/createCert/", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  getQuizzes(id) {
    try {
      return axios("api/get-completed-quizzes/"+id, {
        method: "get",
        headers: {"Content-Type": "application/json"},
      });
    } catch (err) {
      return err;
    }
  },

  getJobClassificationQuizes(ID) {
    try {
      return axios("users/getJobClassificationQuizzes", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {ID},
      });
    } catch (err) {
      return err;
    }
  },


  /////// EMAIL

  getGroupCode(gcid) {
    try {
      return axios("api/groupCode/get/" + gcid, {
        method: "get",
        id: gcid,
      });
    } catch (err) {
      return err;
    }
  },

  createGroupCode(data) {
    try {
      return axios("api/groupCode/create", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  getAllUsers(query) {
    try {
      let url = "users/getAllUsers";
      if (query) url += query
      return axios(url, {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {},
      });
    } catch (err) {
      return err;
    }
  },

  updateGroupCode(data) {
    try {
      return axios("api/groupCode/update", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  // AUTH

  checkEmail(email) {
    //check if email in database
    console.log(email, "DATAA")
    //check password matches hashed password

    //if true then login and set userstate.
    try {
      return axios(`auth/user/email-verify/${email}`, {
        method: "get",
        headers: {"Content-Type": "application/json"},
        // data: data,
      });
    } catch (err) {
      return err;
    }
  },

  login(data) {
    try {
      return axios("auth/login", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  reset_locked(data) {
    try {
      return axios("auth/reset-locked", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {email: data},
      });
    } catch (err) {
      return err;
    }
  },

  register(data) {
    try {
      return axios("auth/register", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  makeAdmin(id) {
    try {
      return axios("auth/set-admin", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {UserID: id},
      });
    } catch (err) {
      return err;
    }
  },

  reset_password(data) {
    try {
      return axios("auth/reset-password", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data,
      });
    } catch (err) {
      return err;
    }
  },

  get_profile(email) {
    try {
      return axios("auth/user/profile/" + email, {
        method: "get",
        headers: {"Content-Type": "application/json"},
      });
    } catch (err) {
      return err;
    }
  },
  save_profile(data) {
    try {
      return axios("auth/user/update-profile", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: data
      });
    } catch (err) {
      return err;
    }
  },
  save_profile_image(image) {
    console.log("image", image)
    try {
      return axios("auth/user/upload-profile-image/" + image.name, {
        method: "post",
        // headers: { "Content-Type": "multipart/form-data" },
        data: image.file
      });
    } catch (err) {
      return err;
    }
  },
  terminate(id) {
    try {
      return axios("auth/user/terminate", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {UserID: id}
      });
    } catch (err) {
      return err;
    }
  },
  reinstate(id) {
    try {
      return axios("auth/user/terminate?reinstate=1", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {UserID: id}
      });
    } catch (err) {
      return err;
    }
  },
  delete(id) {
    try {
      return axios("auth/user/delete", {
        method: "post",
        headers: {"Content-Type": "application/json"},
        data: {UserID: id}
      });
    } catch (err) {
      return err;
    }
  },
  certificatesStatus(email) {
    try {
      let url = "notification/expiration-alert";
      if(email) url += "?user=" + email
      return axios(url, {
        method: "get",
        headers: {"Content-Type": "application/json"}
      });
    } catch (err) {
      return err;
    }
  }
};

export default Api;
