import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import dayjs from "dayjs";
import Api from "../../../../Api/Api";
import language from "../../../../assets/data/language";
import colors from "../../../../colors";
import Button from "@mui/material/Button";

const Notification = (
  {
    spanish,
    userData,
    quizzes,
    user
  }) => {

  const [certificates, setCertificates] = React.useState(false);
  const callRef = React.useRef(false);
  const domEl = React.useRef(null);

  if(!certificates && !callRef.current) {
    callRef.current = true;
    Api.certificatesStatus(user.Email).then(res => {
      // console.log(res, "RES");
      if(res.data && res.data[user.UserID]) setCertificates(res.data[user.UserID]);
      else setCertificates({});
    }).catch(err => console.log(err));
  }
  // console.log(certificates, "CERTIFICATES")

  const getExpiration = (row) => {
    try {
      const quiz = quizzes.filter(q => q.id === row.quiz);
      // console.log(quiz, "QUIZ")
      if(quiz[0]) {
        // console.log(row, quiz[0].english.title, "TITL:E")
        const taken = new Date(row.issued);
        let yearsToAdd;
        let frequency = quiz[0].quizFrequency;
        if(frequency === "Annual") yearsToAdd = 1;
        else if(frequency === "ThreeYears") yearsToAdd = 3;
        else yearsToAdd = 2;
        const expiration = dayjs(taken.setFullYear(taken.getFullYear() + yearsToAdd)).format('MM/DD/YYYY');
        return expiration
      } else return ""
    } catch (e) {
      return ""
    }

  }

  return <div id="domEl" ref={domEl} style={{
    backgroundColor: colors.Theme.offWhite,
    width: '100%',
    borderRadius: 10,
    marginTop: 40,
    color: 'black',
    padding: 20,
    overflow: "scroll"
  }}>
    <h3 style={{marginBottom: 20, textAlign: 'center'}}>{spanish ? 'Certificados de ' + user.JobClassification : user.JobClassification + " Certificates"}</h3>
    {certificates && <>
      <div style={styles.notification.section}>
        <h5 style={styles.notification.tableHead}>{language.certificates.expired(spanish)}</h5>
        <table style={styles.notification.table}>
          <tr style={{fontWeight: 'bold'}}>
            <td style={{width: '40%', border: '1px solid black'}}>{language.certificates.certificates(spanish)}</td>
            <td style={styles.notification.tableD}>{language.certificates.issued(spanish)}</td>
            <td style={styles.notification.tableD}>{language.certificates.expired(spanish)}</td>
          </tr>
          {certificates.expired.map((cert, i) => <tr key={i}>
            <td style={styles.notification.tableD}>{spanish && cert.spanishTitle ? cert.spanishTitle : cert.title}</td>
            <td style={styles.notification.tableD}>{cert.issued}</td>
            <td style={styles.notification.tableD}>{dayjs(cert.expiration).format('MM/DD/YYYY')}</td>
          </tr>)}
        </table>
      </div>
      <div style={styles.notification.section}>
        <h5 style={styles.notification.tableHead}>{language.certificates.expiring(spanish)}</h5>
        <table style={styles.notification.table}>
          <tr style={{fontWeight: 'bold', textAlign: 'center'}}>
            <td style={{width: '40%', border: '1px solid black'}}>{language.certificates.certificates(spanish)}</td>
            <td style={styles.notification.tableD}>{language.certificates.issued(spanish)}</td>
            <td style={styles.notification.tableD}>{language.certificates.expiring(spanish)}</td>
          </tr>
          {certificates.expiring.map((cert, i) => <tr key={i}>
            <td style={styles.notification.tableD}>{spanish && cert.spanishTitle ? cert.spanishTitle : cert.title}</td>
            <td style={styles.notification.tableD}>{cert.issued}</td>
            <td style={styles.notification.tableD}>{dayjs(cert.expiration).format('MM/DD/YYYY')}</td>
          </tr>)}
        </table>
      </div>
      <div style={styles.notification.section}>
        <h5 style={styles.notification.tableHead}>{language.certificates.completed(spanish)}</h5>
        <table style={styles.notification.table}>
          <tr style={{fontWeight: 'bold'}}>
            <td style={{width: '40%', border: '1px solid black'}}>{language.certificates.certificates(spanish)}</td>
            <td style={styles.notification.tableD}>{language.certificates.issued(spanish)}</td>
            <td style={styles.notification.tableD}>{language.certificates.expiring(spanish)}</td>
          </tr>
          {certificates.completed.map((cert, i) => <tr key={i}>
            <td style={styles.notification.tableD}>{spanish && cert.spanishTitle ? cert.spanishTitle : cert.title}</td>
            <td style={styles.notification.tableD}>{cert.issued}</td>
            <td style={styles.notification.tableD}>{getExpiration(cert)}</td>
          </tr>)}
        </table>
      </div>
      <div style={styles.notification.section}>
        <h5 style={styles.notification.tableHead}>{language.certificates.pending(spanish)}</h5>
        <table style={styles.notification.table}>
          <tr style={{fontWeight: 'bold'}}>
            <td style={{width: '40%', border: '1px solid black'}}>{language.certificates.certificates(spanish)}</td>
          </tr>
          {certificates.pending.map((cert, i) => <tr key={i}>
            <td style={styles.notification.tableD}>{spanish && cert.spanishTitle ? cert.spanishTitle : cert.title}</td>
          </tr>)}
        </table>
      </div>
    </>}

  </div>
}

Notification.propTypes = {
  userData: PropTypes.any,
  spanish: PropTypes.any,
  quizzes: PropTypes.any,
  user: PropTypes.any
}

export default Notification;
