import language from "../../../../assets/data/language";
import Modal from "@mui/material/Modal";
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles";
import logo from "../../../../assets/images/logoColor.png";
import * as htmlToImage from 'html-to-image';
import Button from "@mui/material/Button";
import dayjs from "dayjs";


const GenerateCard = (
  {
    modalOpen,
    closeModal,
    spanish,
    userData,
    quizzes,
    user
  }) => {
  const domEl = React.useRef(null);
  const downloadImage = async () => {
    const dataUrl = await htmlToImage.toPng(domEl.current);

    // download image
    const link = document.createElement('a');
    link.download = `TrainingVerificationCard-${user.FirstName}-${user.LastName}.png`;
    link.href = dataUrl;
    link.click();
  }

  const getExpiration = (row) => {
    try {
      const quiz = quizzes.filter(q => q.id === row.certificate?.metadata?.quiz);
      if(row.expiration !== "") return row.expiration
      else if(quiz[0]) {
        const taken = new Date(row.date);
        let yearsToAdd;
        let frequency = quiz[0].quizFrequency;
        if(frequency === "Annual") yearsToAdd = 1;
        else if(frequency === "ThreeYears") yearsToAdd = 3;
        else yearsToAdd = 2;
        const expiration = dayjs(taken.setFullYear(taken.getFullYear() + yearsToAdd)).format('MM/DD/YYYY');
        return expiration
      } else return ""
    } catch (e) {
      return ""
    }

  }

  return <Modal
    open={modalOpen}
    onClose={closeModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <div style={{backgroundColor: 'white', borderRadius: 10, overflow: 'scroll', maxHeight: '90vh'}}>
      <div
        style={styles.card.btn}
      >
        <Button
          onClick={downloadImage}
          variant="contained"
          style={{}}
        >{language.common.download(spanish)}</Button>
      </div>
      <div id="domEl" ref={domEl} style={{
        backgroundColor: 'white',
        maxWidth: '100%',
        padding: 20,
        overflow: "scroll"
      }}>
        <div style={{...styles.card.container, height: 300}}>
          <img src={logo} style={styles.card.img}/>
          <h5>Training Verification Card</h5>
          <div>
            <p style={styles.card.employee}>
              {user.FirstName} {user.LastName}
            </p>
            <p style={styles.card.smallText}>Employee Name</p>
          </div>
          <p style={styles.card.smallText}>
            This employee has successfully completed all required training listed on the backside of this card with the instructors&apos; signature
          </p>
        </div>
        <div style={{...styles.card.container, minHeight: 300}}>
          <table style={styles.card.table}>
            <tr style={{fontWeight: 'bold'}}>
              <td style={{width: '40%', border: '1px solid black'}}>Training Type</td>
              <td style={styles.card.tableD}>Issued</td>
              <td style={styles.card.tableD}>Expiration</td>
              <td style={styles.card.tableD}>Instructor</td>
            </tr>
            {userData && userData.map((d, i) => <tr key={i}>
              <td style={styles.card.tableD}>{d.quiz ? d.quiz.replace('.pdf', "") : d.name}</td>
              <td style={styles.card.tableD}>{d.date}</td>
              <td style={styles.card.tableD}>{getExpiration(d)}</td>
              <td style={styles.card.tableD}>Joseph Flowers</td>
            </tr>)}
          </table>
        </div>
    </div>


    </div>
  </Modal>
}

GenerateCard.propTypes = {
  userData: PropTypes.any,
  quizzes: PropTypes.any,
  modalOpen: PropTypes.bool,
  closeModal: PropTypes.any,
  spanish: PropTypes.any,
  user: PropTypes.any
}

export default GenerateCard;
