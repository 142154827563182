import React, {useEffect, useState} from "react";
import bcrypt from "bcryptjs";
import Api from "../../../Api/Api";
import authStyles from "../../auth/styles";
import language from "../../../assets/data/language";
import {FormControl, Grid, MenuItem, Select} from "@mui/material";
import userData from "../../../assets/data/user";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import colors from "../../../colors";
import styles from "../../auth/styles";
import emailjs from "emailjs-com";

const FormInput = ({label, type, value, onChange, required, side, showPassword, setShowPassword}) => {
  let formType = type;
  if (formType === "password" && showPassword) formType = "text"
  return (
    <label id={label} htmlFor={label} style={side ? authStyles.form.labelSide : authStyles.form.label}>
          <span style={{fontSize: '1rem', color: colors.Theme.black}}>
              {label}
            {required && <span style={{color: 'red'}}>*</span>}
          </span>
      <div style={authStyles.form.inputContainer}>
        <input
          style={authStyles.form.input}
          type={formType}
          value={value}
          onChange={onChange}
          required={required}
        />
        {type === "password" && <div style={authStyles.form.inputIcon} onClick={() => setShowPassword(!showPassword)}>
          <Icon fontSize="small">{showPassword ? "visibility_off" : "visibility"}</Icon>
        </div>}
      </div>

    </label>
  )
};
FormInput.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  showPassword: PropTypes.bool,
  setShowPassword: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  required: PropTypes.bool.isRequired,
  side: PropTypes.bool
};

const formComplete = (form) => {
  const keys = Object.keys(form);
  let completed = []
  for (let k in keys) {
    if (form[keys[k]] !== "") completed.push(k);
  }
  return completed.length === keys.length;
}

const CreateUser = ({onSubmit, cancel}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [classification, setClassification] = useState("");
  const [identification, setIdentification] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [apiMessage, setApiMessage] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const spanish = false;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      setPasswordsMatch(true);
      try {
        const salt = bcrypt.genSaltSync();
        const hashedPassword = bcrypt.hashSync(password, salt);
        const user = {
          FirstName: firstName,
          LastName: lastName,
          email: email,
          password: password,
          CompanyID: identification,
          JobClassification: classification,
          adminCreated: true
        };

        // let test = Api.register(user);
        await Api.register(user).then(response => {
          console.log(response);
          if(response) {
            emailjs.send("service_leemyzb", "template_itfq0fj", {
              name: `${firstName} ${lastName}`,
              email: email,
              password: password,
              url: 'http://marathonsafetytraining.com'
            }, 'OEpIsDjbJHxouxTll').then((result) => {
              console.log(result, "RES");
              onSubmit()
            }, (error) => {
              console.log(error)
            });
          }
          // onSubmit(response.data)
        }).catch(err => {
          if (err.response) {
            console.log(err.response.data, "ERR")
            setApiMessage(err.response.data)
            if (err.response.data.message.email) {
              console.log('Not Found User')
            }
          } else {
            console.log(err, 'other error?')
          }
        });

        // const data = await response.json();
        // setUser(data.user);
      } catch (error) {
        console.error(error);
      }
      // onSubmit({firstName, lastName, email, password, passwordsMatch});
    } else {
      setPasswordsMatch(false);
    }
  };

  useEffect(() => {
    const complete = formComplete({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      identification,
      classification
    });
    if (!complete !== disabled) {
      setDisabled(!complete)
    }
  }, [
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    identification,
    classification
  ]);

  return (
    <form onSubmit={handleSubmit} style={{
      ...authStyles.form.content,
      backgroundColor: colors.Theme.offWhite,
      padding: 10,
      borderRadius: 10
    }}>
      {apiMessage && <div style={{...authStyles.apiMessage, marginBottom: 15}}>
        {(apiMessage && apiMessage.email) && <>
          <p style={authStyles.apiMessageHeading}>{apiMessage.email}</p>
        </>}
        {(apiMessage && apiMessage.token) && <>
          <p style={authStyles.apiMessageHeading}>{apiMessage.token}</p>
        </>}
        {(apiMessage && apiMessage.password) && <>
          <p style={authStyles.apiMessageHeading}>{Array.isArray(apiMessage.password) ? "Password" : apiMessage.password}</p>
          {Array.isArray(apiMessage.password) && <ul style={authStyles.apiMessageUl}>
            {apiMessage.password.map((msg, i) => <li key={i} style={authStyles.apiMessageLi}>{msg}</li>)}
          </ul>}

        </>}

      </div>}
      <div style={authStyles.form.sideBySide}>
        <FormInput
          label={language.auth.fName(spanish)}
          type="text"
          side
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required={true}
        />
        <FormInput
          label={language.auth.lName(spanish)}
          type="text"
          side
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required={true}
        />
      </div>
      <FormInput
        label={language.auth.email(spanish)}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />
      <label id="jobClassification" htmlFor="jobClassification" style={{...authStyles.form.label, color: colors.Theme.black}}>
                  <span style={{fontSize: '1rem'}}>
                      {language.auth.company(spanish)}
                    <span style={{color: 'red'}}> *</span>
                  </span>
        <FormControl>
          <Select
            style={authStyles.form.input}
            label={language.auth.company(spanish)}
            value={identification}
            onChange={e => setIdentification(e.target.value)}
          >
            {userData.companyId.map((id, i) => <MenuItem key={i} style={{height: 50, fontSize: '1.2rem'}}
                                                         value={id}>{id}</MenuItem>)}
          </Select>
        </FormControl>
      </label>
      <label id="jobClassification" htmlFor="jobClassification" style={{...authStyles.form.label, color: colors.Theme.black}}>
                <span style={{fontSize: '1rem'}}>
                    {language.auth.classificationJob(spanish)}
                  <span style={{color: 'red'}}>   *</span>
                </span>
        <FormControl>
          <Select
            style={authStyles.form.input}
            label={language.auth.classification(spanish)}
            value={classification}
            onChange={e => setClassification(e.target.value)}
          >
            {userData.classification.map((cls, i) => <MenuItem key={i} style={{height: 50, fontSize: '1.2rem'}}
                                                               value={cls}>{cls}</MenuItem>)}
          </Select>
        </FormControl>
      </label>
      <div style={authStyles.form.sideBySide}>
        <FormInput
          label={language.auth.password(spanish)}
          type={'password'}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          side
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required={true}
        />
        <FormInput
          label={language.auth.confirmP(spanish)}
          type={'password'}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          side
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required={true}
        />
      </div>
      {!passwordsMatch && <p style={authStyles.form.message}>{language.auth.nomatch(spanish)}</p>}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
        <button
          onClick={cancel}
          style={authStyles.form.button}
        >Cancel
        </button>
        <button
          onClick={disabled ? null : handleSubmit}
          style={disabled ? authStyles.form.disabledButton : {...authStyles.form.button, backgroundColor: colors.Theme.successLight}}
        >Send User Email Invitation
        </button>
      </div>

    </form>
  );
};

CreateUser.propTypes = {
  onSubmit: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  cancel: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CreateUser;
